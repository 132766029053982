import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'

// Components
import Layout from 'components/Layout'


function PrivacyDeclaration() {
  // const { wordpressPage: page, site: { siteMetadata: { defaultTitle: title } } } = useStaticQuery(graphql`
  //   query PrivacyDeclarationQuery {
  //     wordpressPage(wordpress_id: {eq: 3}) {
  //       title
  //       content
  //     }
  //     site {
  //       siteMetadata {
  //         defaultTitle: title
  //       }
  //     }
  //   }
  // `)

  return (
    <Layout>
      {
        // <SEO title={`${page.title} | ${title}`} />
        // <div className="container" dangerouslySetInnerHTML={{ __html: page.content }} />
      }
      
    </Layout>
  )
}

export default PrivacyDeclaration